import React from 'react';
import { styled, Container } from '@mui/material';

const HeaderContainer = styled('header')(({ theme }) => ({
  width: '100%',
  backgroundImage: 'url(/images/bg.png)',
  backgroundRepeat: 'repeat-x',
  backgroundPosition: '0 69px',
  backgroundSize: '100% 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0),
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    backgroundPosition: '0px 50px', // Adjust the position for smaller screens
  },
}));

const LogoContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  maxWidth: 'initial',
  padding: theme.spacing(0),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 0, // Set left padding to 0 for screens wider than 600px
    // paddingRight: '24px', // Maintain the right padding of 24px
  },
}));

const GompaPrahaLogo = styled('img')(({ theme }) => ({
  maxWidth: '200px',
  width: '100%', // Add this line to make the width responsive
  height: 'auto',
  position: 'relative',
  top: theme.spacing(2.2),
  [theme.breakpoints.down('xs')]: {
    maxWidth: '130px',
  },
}));

const BdcLogo = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  marginTop: theme.spacing(0), // Add margin top to align with the red line
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(0), // Adjust margin top for medium screens
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1), // Adjust margin top for medium screens
  },
}));

export const Header = () => (
  <HeaderContainer>
    <LogoContainer>
      <a href="/">
        <GompaPrahaLogo src="/images/gompa-praha-logo.webp" alt="GOMPA PRAHA" />
      </a>
      <a href="https://bdc.cz/">
        <BdcLogo src="/images/logo.png" alt="Buddhismus Diamantové cesty linie Karma Kagjü" />
      </a>
    </LogoContainer>
  </HeaderContainer>
);

export default Header;