import React from 'react';
import { Box, Typography, styled, Container } from '@mui/material';

const TextSection = styled('section')(({ theme }) => ({
  margin: theme.spacing(2),
  marginTop: theme.spacing(0),
  width: '80%',
  textAlign: 'center', // Center text horizontally
}));

const BudovatelPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <h1>Budovatel</h1>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          {/* Flexbox properties to center the content */}
          <TextSection>
            <Typography component="div" gutterBottom>
              I ty můžeš koupit do pražského centra „svůj“ kousek. Vyber, co chceš a my se postaráme o jeho nákup. Děkujeme!!!
            </Typography>
          </TextSection>
        </Box>
      </Container>
    </div>
  );
};

export default BudovatelPage;
