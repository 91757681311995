import { request } from '../utilities/httpRequestHandler';
import config from '../config/config';

// Function to submit form data
async function submitFormData(formData, subpage) {
  try {
    // Construct the URL with the subpage parameter
    const url = `${config.API_BASE_URL}/submit/${subpage}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      // Handle non-JSON responses
      const contentType = response.headers.get('content-type');
      let errorDetail = '';
      if (contentType && contentType.includes('application/json')) {
        const errorBody = await response.json();
        errorDetail = errorBody.message || JSON.stringify(errorBody);
      } else {
        // If the response is not JSON, read it as text
        errorDetail = await response.text();
      }
      throw new Error(`${response.status} ${errorDetail}`);
    }

    return await response.json();
  } catch (error) {
    // Ensure that the error message is meaningful
    throw new Error(error.message || 'An error occurred while submitting the form.');
  }
}

// Function to fetch the total count
async function fetchTotalCount() {
  try {
    const url = `${config.API_BASE_URL}/total`;
    const response = await request('GET', url);
    return response;
  } catch (error) {
    console.error("Error fetching total count:", error);
    throw error;
  }
}

export { submitFormData, fetchTotalCount };
