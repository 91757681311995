import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, styled, TextField, Container, Link } from '@mui/material';
import { submitFormData } from '../services/service.js';
import { login } from '../Auth.js';
import config from '../config/config';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'red',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#ff5555',
  },
  margin: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  width: '80%',
}));

const TextSection = styled('section')(({ theme }) => ({
  margin: theme.spacing(2),
  marginTop: theme.spacing(0),
  width: '80%',
  textAlign: 'justify',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.5s ease',
  padding: theme.spacing(2),
  width: 'calc(80% - 32px)',
  marginBottom: theme.spacing(5),
}));

const InfoMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(4),
}));

function CentrifugaPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rateLimitError, setRateLimitError] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    phone: '',
    email: '',
    center: '',
    kolikKamaraduPrijede: '',
    kdySiPredplatitePobytStart: null,
    kdySiPredplatitePobytEnd: null,
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    lastName: '',
    phone: '',
    email: '',
    center: '',
    kolikKamaraduPrijede: '',
    kdySiPredplatitePobytStart: '',
    kdySiPredplatitePobytEnd: '',
  });


  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setIsLoading(true); // Set loading to true when starting to fetch
        const response = await fetch(`${config.API_BASE_URL}/auth/user`, {
          credentials: 'include', // Include credentials for session cookie to be sent
        });
        if (response.ok) {
          const user = await response.json();
          if (user === null) {
            setUserInfo(null);
          } else {
            setUserInfo(user);
            setFormData({
              name: user.name || '',
              lastName: user.lastName || '',
              phone: user.firstPhone || '',
              email: user.email || '',
              center: user.centerName || '',
              kolikKamaraduPrijede: '',
              kdySiPredplatitePobytStart: '',
              kdySiPredplatitePobytEnd: '',
            });
            setShowForm(true);
          }
        } else {
          throw new Error('Unexpected response from /auth/user');
        }
      } catch (error) {
        console.error('Error:', error);
        setUserInfo(null);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };
    fetchUserInfo();
  }, []);


  const handleLogin = () => {
  const preAuthUrl = window.location.href; // Capture the current URL
  // console.log("Storing pre-auth URL:", preAuthUrl);

  // Make a POST request to store this URL in the session on the server
  fetch(`${config.API_BASE_URL}/api/setPreAuthUrl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ preAuthUrl }),
    credentials: 'include', // Include credentials for session cookie to be sent
  }).then(response => {
    if (response.ok) {
      login(); // Proceed with OAuth login if the URL is successfully stored
    } else {
      console.error('Failed to store pre-auth URL');
    }
  }).catch(error => {
    console.error('Error storing pre-auth URL:', error);
  });
};
 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  const validateForm = () => {
    const errors = {};
    const nameRegex = /^[a-zA-ZáéíóúýčďěňřšťžůåøäöüőűąćęłńśźżĺľŕôñÁÉÍÓÚÝČĎĚŇŘŠŤŽŮÅØÄÖÜŐŰĄĆĘŁŃŚŹŻĹĽŔÔÑ\- ]+$/;
    const phoneRegex = /^(?:(?:\+|00)\d{1,3})?[ ]?\d{3}[ ]?\d{1,3}[ ]?\d{1,3}$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !nameRegex.test(formData.name)) {
      errors.name = 'Jméno může obsahovat pouze písmena, mezery a pomlčky';
    }
    if (!formData.lastName || !nameRegex.test(formData.lastName)) {
      errors.lastName = 'Příjmení může obsahovat pouze písmena, mezery a pomlčky';
    }
    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      errors.phone = 'Zadejte platné telefonní číslo';
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Zadejte platný formát e-mailu';
    }
    if (!formData.center || !nameRegex.test(formData.center)) {
      errors.center = 'Název centra může obsahovat pouze písmena, mezery a pomlčky';
    }
    if (!formData.kolikKamaraduPrijede || !numberRegex.test(formData.kolikKamaraduPrijede)) {
      errors.kolikKamaraduPrijede = 'Zadejte platné číslo';
    }

    // Date validation
    if (!formData.kdySiPredplatitePobytStart) {
      errors.kdySiPredplatitePobytStart = 'Musite si vybrat datum';
    } else if (dayjs(formData.kdySiPredplatitePobytStart).isBefore(dayjs(), 'day')) {
      errors.kdySiPredplatitePobytStart = 'Počáteční datum musí být v budoucnosti';
    }
    if (!formData.kdySiPredplatitePobytEnd) {
      errors.kdySiPredplatitePobytEnd = 'Musite si vybrat datum';
    } else if (dayjs(formData.kdySiPredplatitePobytEnd).isBefore(dayjs(), 'day')) {
      errors.kdySiPredplatitePobytEnd = 'Koncové datum musí být v budoucnosti';
    } else if (dayjs(formData.kdySiPredplatitePobytEnd).isBefore(formData.kdySiPredplatitePobytStart, 'day')) {
      errors.kdySiPredplatitePobytEnd = 'Koncové datum nesmí být dříve než počáteční datum';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setIsSubmitting(true); // Disable the button and indicate loading state

    // Calculate the day difference
    const startDate = dayjs(formData.kdySiPredplatitePobytStart);
    const endDate = dayjs(formData.kdySiPredplatitePobytEnd);
    const dayDifference = endDate.diff(startDate, 'day');
    const calculatedAmount = config.CASTKA_NA_OSOBU * parseInt(formData.kolikKamaraduPrijede, 10) * dayDifference;
  
    const submissionData = {
      ...formData,
      amount: calculatedAmount,
      kdySiPredplatitePobytStart: formData.kdySiPredplatitePobytStart,
      kdySiPredplatitePobytEnd: formData.kdySiPredplatitePobytEnd,
      };
      // console.log('Submission Data:', submissionData);

    try {
      await submitFormData(submissionData, 'centrofuga' );
      // Reset form and errors after successful submission
      setFormData({
        name: '',
        lastName: '',
        phone: '',
        email: '',
        center: '',
        kolikKamaraduPrijede: '',
        kdySiPredplatitePobytStart: '',
        kdySiPredplatitePobytEnd: '',
      });
      setFormErrors({
        name: '',
        lastName: '',
        phone: '',
        email: '',
        center: '',
        kolikKamaraduPrijede: '',
        kdySiPredplatitePobytStart: '',
        kdySiPredplatitePobytEnd: '',
      });
      setRateLimitError(''); // Clear any existing rate limit error
      setShowForm(false); // Hide the form
      setIsEmailSent(true); // Set isEmailSent to true after successful submission
    } catch (error) {
      console.error(error);
      const errorMessage = error.message;
      if (errorMessage.includes('429')) {
        setRateLimitError('Překročili jste počet platných požadavků. Zkuste to znovu za hodinu.');
      } else if (errorMessage.includes('400')) {
        setRateLimitError('Došlo k chybě při odesílání formuláře. Zkontrolujte prosím zadané údaje.');
      } else {
        setRateLimitError('Došlo k interní chybě. Zkuste to prosím znovu.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Container maxWidth="md">
      {!isLoading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 5 }}>

          {!userInfo && (
            <SubmitButton variant="contained" onClick={handleLogin}>
              Přihlásit se
            </SubmitButton>
          )}
          <Box sx={{ width: '40%', textAlign: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            {!userInfo ? (
              <InfoMessage variant="body2" sx={{ display: 'inline-block', textAlign: 'center' }}>
                Pro přihlášení do aplikace je vyžadován aktivní účet na{' '}
                <Link href="https://sso.dwbn.org" target="_blank" style={{ color: '#ff0000' }} underline="none">
                  dwbn.org
                </Link>. Kontaktuj{' '}
                <Link href="https://bdc.cz/meditacni-centra/" target="_blank" style={{ color: '#ff0000' }} underline="none">
                  místní centrum
                </Link>{' '}
                Buddhismu Diamantové cesty pro více informací.
              </InfoMessage>
            ) : isEmailSent ? (
              <Typography variant="h6" sx={{ marginTop: '20px', marginBottom: '20px' }}>Email byl úspěšně odeslán. Zkontrolujte prosím svou schránku.</Typography>
            ) : null}
          </Box>


          {userInfo && showForm && (
            <FormContainer>
              <TextField
                label="Jméno"
                name="name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.name}
                onChange={handleInputChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
              />
              <TextField
                label="Příjmení"
                name="lastName"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.lastName}
                onChange={handleInputChange}
                error={!!formErrors.lastName}
                helperText={formErrors.lastName}
              />
              <TextField
                label="Telefon"
                name="phone"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.phone}
                onChange={handleInputChange}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
              <TextField
                label="Centrum"
                name="center"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.center}
                onChange={handleInputChange}
                error={!!formErrors.center}
                helperText={formErrors.center}
              />
              
              <TextField
                label="Kolik kamarádů přijede"
                name="kolikKamaraduPrijede"
                type="number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.kolikKamaraduPrijede}
                onChange={handleInputChange}
                error={!!formErrors.kolikKamaraduPrijede}
                helperText={formErrors.kolikKamaraduPrijede}
              />

              <LocalizationProvider  dateAdapter={AdapterDayjs}>
                <DatePicker sx={{ mt: 2 }}
                  label="Od"
                  value={formData.kdySiPredplatitePobytStart}
                  onChange={(newValue) => {
                    setFormData({ ...formData, kdySiPredplatitePobytStart: newValue });
                    // Validate directly in the onChange handler
                    if (newValue === null) {
                      setFormErrors({ ...formErrors, kdySiPredplatitePobytStart: 'Musite si vybrat datum' });
                    } else if (newValue.isBefore(dayjs(), 'day')) {
                      setFormErrors({ ...formErrors, kdySiPredplatitePobytStart: 'Počáteční datum musí být v budoucnosti' });
                    } else {
                      setFormErrors({ ...formErrors, kdySiPredplatitePobytStart: '' });
                    }
                  }}
                  slotProps={{
                    textField: {
                      error: !!formErrors.kdySiPredplatitePobytStart,
                      helperText: formErrors.kdySiPredplatitePobytStart || ' ',
                    },
                  }}
                />
                <DatePicker sx={{ ml:3, mt: 2 }}
                  label="Do"
                  value={formData.kdySiPredplatitePobytEnd}
                  onChange={(newValue) => {
                    setFormData({ ...formData, kdySiPredplatitePobytEnd: newValue });
                    // Validate directly in the onChange handler
                    if (newValue === null) {
                      setFormErrors({ ...formErrors, kdySiPredplatitePobytEnd: 'Musite si vybrat datum' });
                    } else if (newValue.isBefore(dayjs(), 'day')) {
                      setFormErrors({ ...formErrors, kdySiPredplatitePobytEnd: 'Koncové datum musí být v budoucnosti' });
                    } else if (newValue.isBefore(formData.kdySiPredplatitePobytStart, 'day')) {
                      setFormErrors({ ...formErrors, kdySiPredplatitePobytEnd: 'Koncové datum nesmí být dříve než počáteční datum' });
                    } else {
                      setFormErrors({ ...formErrors, kdySiPredplatitePobytEnd: '' });
                    }
                  }}
                  slotProps={{
                    textField: {
                      error: !!formErrors.kdySiPredplatitePobytEnd,
                      helperText: formErrors.kdySiPredplatitePobytEnd || ' ',
                    },
                  }}
                />
              </LocalizationProvider>

              <Typography sx={{ textAlign: 'center' }}>
                "Moc děkujeme!"
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1 }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                  {isSubmitting ? 'Zpracovávam...' : 'ANO'}
                </Button>
              </Box>
              {rateLimitError && (
                <Typography color="error" style={{ marginTop: '10px', textAlign: 'center' }}>
                  {rateLimitError}
                </Typography>
              )}
            </FormContainer>
          )}
          {!isEmailSent && (
            <TextSection>
              <Typography component="div" gutterBottom>
                Úžasné - roztoč to se svým centrem v Praze! Moc si toho vážíme a těšíme se na vás. Teď jen
                zbývá přihlásit se do DW Connect, napsat nám kdy přijedete a v jakém počtu.
              </Typography>
              {/* <Typography component="div" gutterBottom>
                Cena pro jednu osobu je {config.CASTKA_NA_OSOBU} Kč. Zaplať teď a můžeš se těšit na pobyt v novém pražském centru. 
              </Typography> */}
            </TextSection>
          )}
        </Box>
        )}
      </Container>
    </>
  );
}

export default CentrifugaPage;