import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import HomePage from './pages/GompaPrahaHomepage';
import Header from './components/Header';
import Footer from './components/Footer';
import BankerPage from './pages/BankerPage';
import BohacPage from './pages/BohacPage';
import BudovatelPage from './pages/BudovatelPage';
import CentrifugaPage from './pages/CentrifugaPage';
import FunraisingPage from './pages/FunraisingPage';
import PoukazPage from './pages/PoukazPage';
import InvestorPage from './pages/InvestorPage';
import VsePage from './pages/VsePage';

function App() {
  return (
    <BrowserRouter>
      <Container maxWidth="lg">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/banker" element={<BankerPage />} />
          <Route path="/bohac" element={<BohacPage />} />
          <Route path="/budovatel" element={<BudovatelPage />} />
          <Route path="/centrofuga" element={<CentrifugaPage />} />
          <Route path="/funraising" element={<FunraisingPage />} />
          <Route path="/poukaz" element={<PoukazPage />} />
          <Route path="/investor" element={<InvestorPage />} />
          <Route path="/vse" element={<VsePage />} />
        </Routes>
        <Footer />
      </Container>
    </BrowserRouter>
  );
}

export default App;