import React from 'react';
import { Typography, Box } from '@mui/material';

function Footer() {
  // Function to dynamically create maito link
  const createMailtoLink = () => {
    const user = 'jozef.huscavaZA';
    const domain = 'Vgmail.com';
    return `mailto:${encodeURIComponent(user)}${encodeURIComponent(domain)}`;
  };

  const encodeEmail = () => {
    const user = 'jozef.huscavaZA';
    const domain = 'Vgmail.com';
    const encodedUser = user.split('').map(char => '&#' + char.charCodeAt(0) + ';').join('');
    const encodedDomain = domain.split('').map(char => '&#' + char.charCodeAt(0) + ';').join('');
    return <span dangerouslySetInnerHTML={{ __html: `${encodedUser}${encodedDomain}` }}></span>;
  };

  return (
    <Box component="footer" sx={{ width: '100%', height: '30px', backgroundColor: 'grey.200', py: 3, mt: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        V případě problémů:{' '}
        <a href={createMailtoLink()} underline="none" rel="noopener noreferrer">
          {encodeEmail()}
        </a>
      </Typography>
      <Typography variant="caption" display="block" align="center">
        2024
      </Typography>
    </Box>
  );
}

export default Footer;
