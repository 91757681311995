import React from 'react';
import { Box, Typography, styled, Container } from '@mui/material';


const TextSection = styled('section')(({ theme }) => ({
  margin: theme.spacing(2),
  marginTop: theme.spacing(0),
  width: '80%',
  textAlign: 'justify',
}));

const StyledLink = styled('a')(({ theme }) => ({
  backgroundColor: 'red',
  color: 'white',
  padding: '8px 16px', // Adjust padding to match your design needs
  textTransform: 'uppercase',
  textDecoration: 'none', // Removes underline from links
  fontWeight: 'bold',
  display: 'inline-block', // Ensure it's inline but also block for padding
  borderRadius: '4px', // Optional: if you want rounded corners
  transition: 'background-color 0.3s ease', // Smooth transition for hover effect
  '&:hover': {
    backgroundColor: '#ff5555',
  },
  marginBottom: theme.spacing(2), // Adds vertical space above the link
}));


function InvestorPage() {

  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 5 }}>
          <TextSection>
            <Typography component="div" gutterBottom style={{ marginBottom: '20px' }}>
              Je úžasné, že chceš sdílet své dobré podmínky a podpořit projekt GP. Takové štědrosti si
              nesmírně vážíme. Při práci na našich projektech je důležité nejen finančně přispět, ale také z
              toho mít radost. Pokud máš tento druh nadbytku, jsi na správném místě. A jak můžeš
              přispět?
            </Typography>
            <Typography component="div" gutterBottom>
             <strong>PŮJČKA</strong> - Jsi ochoten podpořit projekt GP finanční půjčkou, kterou Ti vrátíme v řádu let? Tvá štědrost je pro celou Sanghu neocenitelná. Děkujeme!
            </Typography>
            <Typography component="div" gutterBottom>
              <StyledLink href="/banker">CHCI PŮJČIT</StyledLink>
            </Typography>
            <Typography component="div" gutterBottom style={{ marginBottom: '20px' }}>
             <strong>TRVALÁK</strong> - Chceš podpořit projekt GP navýšením svého trvalého příkazu nebo si ho založit? Skvělé! Vbíháme do cílové rovinky a usilovně pracujeme na tom, 
              abychom mohli pražské centrum otevřít a začít používat na konci září. Velmi si vážíme stálé podpory a budeme vděční, pokud svou podporu do konce roku ještě zvýšíš. Děkujeme!
            </Typography>
            <Typography component="div" gutterBottom>
             <strong>DAR</strong> - Chceš projevit svou štědrost jednorázovým darem na projekt GP? Velmi si toho vážíme.
              Příjemným bonusem je, že dar na GP si můžeš odečíst z daní. To samozřejmě platí i pro Trvalák :-)
            </Typography>

            <Box 
              component="img" 
              src="/images/dar_qr.png" 
              alt="QR kôd pro jednorázový dar" 
              sx={{ 
                width: {
                  xs: '70%',
                  md: '25%'
                },
                height: 'auto', 
                display: 'block', 
                mx: {
                  xs: 'auto',
                  md: 0      
                },
                my: 2 
              }} 
            />

            <Typography component="div" gutterBottom sx={{textAlign: 'left'}}>
              <p>Pro platbu načti QR kód v internetovém bankovnictví</p>
              <div><strong>č.ú.:</strong> 86-8973530207/0100</div>
              <div><strong>variabilní symbol:</strong> RRMMDD0114 (tzn. např. 7901120114)</div>
              <div><strong>text do zprávy:</strong> Příjmení/DAR/PRAHA</div>
            </Typography>


            <Typography component="div" gutterBottom>
              <StyledLink href="/bohac">CHCI DAROVAT</StyledLink>
            </Typography>
            <Typography component="div" gutterBottom>
              Na závěr je důležité zmínit, že dary budou použity převážně na vnitřní vybavení prostor centra. Ještě jednou děkujeme za podporu!
            </Typography>
          </TextSection>
        </Box>
      </Container>
    </>
  );
}

export default InvestorPage;