import { Box, Typography, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const tiles = [
  { image: 'images/tiles/funraising-1.webp', hoverImage: 'images/tiles/funraising-hover.webp', uri: '/funraising' },
  { image: 'images/tiles/investor-1.webp', hoverImage: 'images/tiles/investor-hover.webp', uri: '/investor' },
  { image: 'images/tiles/vse-1.webp', hoverImage: 'images/tiles/vse-hover.webp', uri: '/vse' },
];

export default function GompaPrahaHomepage() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (uri) => {
    navigate(uri);
  };

  return (
    <Box>
        <Typography pt={7} variant="body1" align="justify" gutterBottom>
          Vítej na stránkách podpory projektu Gompa Praha.
        </Typography>
        <Typography pt={1} variant="body1" align="justify" gutterBottom>
          Rekonstrukcí pražského centra vzniká reprezentativní místo naplňující vysoké standardy
          buddhistických center Diamantové cesty v dalších významných evropských metropolích.
          Praha, která leží v srdci Evropy a České republiky, je přirozenou křižovatkou pro všechny
          praktikující přátele i pro všechny, kteří se chtějí s Buddhovým učením teprve setkat.
        </Typography>
        <Typography pt={0} variant="body1" align="justify" gutterBottom>
          Věříme, že po znovu otevření centra zde budou všichni přátelé rádi trávit čas, ať už na
          meditačních víkendech, přednáškách či pobytech v hlavním městě. Každého zde rádi uvidíme
          a věříme, že se pražské centrum stane i Tvým centrem (pokud tomu již tak není :-)
        </Typography>
        <Typography pt={0} variant="body1" align="justify" gutterBottom>
          Dokončení projektu je možné jen díky financím, přáním a zájmu celé české a světové Sanghy.
        </Typography>
        <Typography pt={1} sx={{ mb: 4 }} variant="body1" align="justify" gutterBottom>
          Děkujeme Ti proto za Tvou důvěru, štědrost a podporu. Všeho si nesmírně vážíme.
        </Typography>
      <Grid container spacing={2}>
        {tiles.map((tile, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                position: 'relative',
                cursor: 'pointer',
                overflow: 'hidden',
                '&:hover img': {
                  transform: 'scale(1.1)',
                },
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleTileClick(tile.uri)}
            >
              <img
                src={hoveredIndex === index ? tile.hoverImage : tile.image}
                alt={`Tile ${index + 1}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  transition: 'transform 0.4s',
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Typography variant="subtitle1" align="center" sx={{ mt: 4 }}>
        Celý projekt má velké požehnání našeho drahocenného Lamy Oleho, který v souvislosti
        s projektem Gompa Praha v březnu 2023 řekl:
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “It is something else and if you will do it you will have people.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “It is a very good thing to do that.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “I like the people.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “We keep it on.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “I really want to benefit you and everybody.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “We can do it.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “I just wanna see people be happy happy happy happy.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “Don't give anything up.”
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mt: 1, fontStyle: 'italic' }}>
        “That's what we should do.”
      </Typography>


    </Box>
  );
}