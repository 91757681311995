const request = async (method, url, data = null) => {
  const config = {
    method,
    credentials: 'include', // Include cookies with the request
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (data && method !== 'GET' && method !== 'HEAD' && method !== 'DELETE') {
    // Only set body for non-GET/HEAD requests
    config.body = JSON.stringify(data);
  } else {
    delete config.headers['Content-Type'];
  }

  try {
    const response = await fetch(url, config);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export { request };
