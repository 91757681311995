import React from 'react';
import config from '../config/config';
import { Box, Typography, styled, Container } from '@mui/material';

const TextSection = styled('section')(({ theme }) => ({
  margin: theme.spacing(2),
  marginTop: theme.spacing(0),
  width: '80%',
  textAlign: 'justify',
}));

const StyledLink = styled('a')(({ theme }) => ({
  backgroundColor: 'red',
  color: 'white',
  padding: '8px 16px', // Adjust padding to match your design needs
  textTransform: 'uppercase',
  textDecoration: 'none', // Removes underline from links
  fontWeight: 'bold',
  display: 'inline-block', // Ensure it's inline but also block for padding
  borderRadius: '4px', // Optional: if you want rounded corners
  transition: 'background-color 0.3s ease', // Smooth transition for hover effect
  '&:hover': {
    backgroundColor: '#ff5555',
  },
  marginBottom: theme.spacing(2), // Adds vertical space above the link
}));


function VsePage() {

  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 5 }}>
          <TextSection>
            <Typography component="div" gutterBottom style={{ marginBottom: '20px' }}>
              Je skvělé, že chceš sdílet své dobré podmínky a podpořit projekt GP. Moc si toho vážíme! Při podpoře našich projektů se umíme dobře bavit a dělat věci s radostí a hlavně spolu.
              Jak se můžeš zapojit?
            </Typography>
            <Typography component="div" gutterBottom>
              <strong>POUKAZ SNŮ</strong> - Chceš přespat v novém pražském centru? Zde si můžeš koupit Poukaz snů na pobyt v novém dormitory ve stylu EC, které ve své nové podobě nabídne dokonce i přespání na lůžku pro
              dva. Cena pro jednu osobu je {config.CASTKA_NA_OSOBU} Kč. Zaplať teď a můžeš se těšit na pobyt v novém pražském centru. Těšíme se až přijedeš.
            </Typography>
            <Typography component="div" gutterBottom>
              <StyledLink href="/poukaz">CHCI POUKAZ SNŮ</StyledLink>
            </Typography>
            <Typography component="div" gutterBottom>
              <strong>CENTROFUGA</strong> - Roztoč to se svým centrem v Praze. Podpoř projekt GP tím, že vezmeš svoje centrum do
              Prahy! Při podpoře našich projektů se umíme dobře bavit, dělat věci s radostí, a hlavně spolu
              trávit čas. A nejlépe, když je nás u toho hodně! Domluv se se svým centrem, sbalte skvělou
              náladu, přijeďte do Prahy a zůstaňte, jak dlouho chcete. Na konci září centrum slavnostně
              otevřeme, už nyní si můžeš zarezervovat termín pro váš pobyt v nově zrekonstruovaném pražském centru.
            </Typography>
            <Typography component="div" gutterBottom>
              <StyledLink href="/centrofuga">CHCI TO ROZTOČIT</StyledLink>
            </Typography>
          </TextSection>
        </Box>
      </Container>
    </>
  );
}

export default VsePage;