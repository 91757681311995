import { request } from './utilities/httpRequestHandler';
import config from './config/config';

const { SSO_URL, CLIENT_ID, REDIRECT_URI } = config;

// Determine the 
const AUTH_URL = `${SSO_URL}/oauth2/authorize/`

export const login = async () => {
    const state = Math.random().toString(36).substring(7);
    const scope = 'openid profile email phone';
    const authUrl = `${AUTH_URL}?scope=${scope}&response_type=code&state=${state}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
  
    try {
      // Store the state value in the server-side session
      await request('POST', `${config.API_BASE_URL}/auth/state`, { state });
  
      window.location.href = authUrl;
    } catch (error) {
      console.error('Failed to store state value:', error);
      throw error;
    }
  };

export const getUserInfo = async () => {
  try {
    const response = await request('GET', '/auth/user');
    return response;
  } catch (error) {
    throw error;
  }
};
